// @import "../scss/icons.scss";
//SignUp Modal close
.close {
    background: transparent;
    border: 0;
    font-size: 12px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")) center / 1em auto no-repeat;
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
}

.close span {
    display: none;
}

//section country/type layout 1
.filter-search-form {
    .selectForm__inner {
        box-shadow: none !important;
        border: 0;
        font-size: 15px;
        height: 60px;
        padding: 16px 35px 15px 45px !important;
        border-radius: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.filter-input-box {
    padding: 16px 6px 15px 45px;
}

.filter-search-form {
    .selectForm__inner {
        box-shadow: none !important;
        border: 0;
        font-size: 15px;
        height: 60px;
        padding: 16px 35px 15px 45px !important;
        border-radius: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

//JobList
.filler-job-form {
    .form-select-option {
        box-shadow: none !important;
        border: 1;
        font-size: 14px;
        height: 44px;
        padding: 6px 42px 7.5px 45px !important;
        text-overflow: ellipsis;
        border-radius: 6px;
    }
}

.filter-job-input-box-option {
    box-shadow: none !important;
    border: 1;
    font-size: 14px;
    height: 44px;
    padding: 6px 6px 7.5px 45px !important;
    border-radius: 6px;
}

//JobGrid
.filler-job-form {
    .selectForm__inner {
        box-shadow: none !important;
        border: 1;
        font-size: 14px;
        height: 44px;
        padding: 6px 6px 7.5px 45px !important;
        border-radius: 6px;
    }
}

.filler-job-input-box {
    box-shadow: none !important;
    border: 1;
    font-size: 14px;
    height: 44px;
    padding: 6px 6px 7.5px 45px !important;
    border-radius: 6px;
}

//Feature icon,Pricing Icon
.featrue-icon,
.pricing-icon,
.popu-category-icon,
.avatar-xs,
.mode-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
    line-height: inherit;
}


.comp-logo,
.job-logo {
    width: 70px;
    height: 70px;
    border: 1px solid #eee;
    object-fit: cover;
}

//Candidate List (BookMark Icon)
.favorite-icon {
    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

//Profile status Icon
.profile-active {
    padding: 5px;
}

//Candidate Details icons
.list-unstyled {
    li {
        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

//About us/pricing page Counter
.counter_custom {
    .counter {
        height: 1.2em !important;
    }
}

.top-bar {
    display: flex;
    align-items: center;
    height: 44px;
}

.noUi-horizontal {
    height: 10px;
    .noUi-handle {
        height: 18px;
        width: 18px;
        top: -5px;
    }
}

.noUi-tooltip {
    font-size: 12px;
    padding: 1px 4px;
}

.cta-heading {
    strong {
        color: #F7CC53;
    }
}

.cat-image {
    width: auto;
    height: 32px;
}

.banner-text {
    strong {
        color: #F7CC53;
    }
}

.key-feature-img {
    width: 25px;
}

.subscribe-form {
    position: relative;
    z-index: 100;
}

.title {
    strong {
        color: #F7CC53;
    }
}

.signup-modal {
    max-width: 650px;
}

.tabs-sec {
    display: flex;
    margin-bottom: 20px;
}

.tabs-sec .tab {
    border: 1px solid #e0e0e0;
    padding: 16px 15px;
    cursor: pointer;
    width: 100%;
    opacity: 0.5;
}

.tabs-sec .tab.left-tab {
    margin-right: 15px;
}

.tabs-sec .tab {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.tabs-sec .tab:hover {
    background: rgba(118, 109, 244, .07);
    opacity: 1;
}

.tabs-sec .tab.active {
    border: 1px solid #766df4;
    background: rgba(118, 109, 244, .07);
    color: #000;
    opacity: 1;
}

.tabs-sec .tab h6 {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 4px;
}

.tabs-sec .tab h4 {
    font-size: 15px;
    margin-bottom: 0;
    color: #495057;
}

.innerpage-tabs-sec {
    margin-top: 40px;
}

.innerpage-tabs-sec .tab.active {
    background: #EDB828;
    color: #fff !important;
}

.innerpage-tabs-sec .tab.active h4 {
    color: #fff;
}

.innerpage-tabs-sec .tab {
    color: #fff;
}

.innerpage-tabs-sec .tab h4 {
    color: #fff;
}

.my-form-control:focus {
    border-color: #766df4;
    box-shadow: 0 0 0 0.2rem rgba(118, 109, 244, .25);
}

.innerpage-form-control:focus {
    border-color: #ffff;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.dashboard .my-card {
    padding: 20px 15px;
    margin-bottom: 20px;
    color: #495057;
    font-size: 16px;
    border: 1px solid #d1d1d1;
    display: block;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    text-transform: uppercase;
}

.dashboard .my-card:hover {
    background: #f7f7f7;
}

.button-card {
    border: 1px solid #eff0f2;
    padding: 15px 15px;
    text-align: center;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    max-width: 300px;
    margin-left: auto;
}

.button-card:hover {
    background: #f7f7f7;
    color: #766df4;
}

.no-company-btn-card {
    width: 100%;
    max-width: 350px;
    margin: auto;
}

.my-badge {
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: rgb(218, 55, 70,);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -10px;
    right: -5px;
    font-size: 11px;
}

.cust-divider {
    width: 100%;
    height: 1px;
    background-color: #eff0f2;
    margin: 18px 0;
}

.toast-style-success {
    background: #157e59 !important;
}

.cust-dropdown .dropdown-item:hover {
    padding: .625rem .625rem !important;
}

.navbar .header-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .button-card {
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .tabs-sec {
        flex-direction: column;
    }
    .tabs-sec .tab.left-tab {
        margin-bottom: 20px;
    }
}