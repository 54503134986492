.eye-input-div {
  position: relative;
}

.eye-password-input {
  padding-right: 35px;
}

.eye-span {
  position: absolute;
  top: 10;
  right: 15;
  cursor: pointer;
}
