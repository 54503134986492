/* 
.cmspage-sec {
  min-height: 400px;
} */

.cmspage-sec .loading-sec {
  padding: 200px 0 ;
  background: #f9f9f9;
  width: 100%;
  text-align: center;
}

.cmspage-sec .loading-sec img {
  width: 150px;
  height: 150px;
}

.cmspage-sec .loading-sec h5 {
  text-align: center;
}
.cmspage-sec .content-sec {
  padding: 70px 0 100px;
}

.cmspage-sec .content-sec h2 {
  margin-bottom: 30px;
}

.cmspage-sec .content-sec p {
  font-size: 18px;
}
