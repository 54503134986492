.verify-email-sec {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.verify-email-box {
  max-width: 500px;
}

.verify-email-box .title {
  margin-bottom: 30px;
}

.verify-email-box .my-button {
  margin: 10px;
  min-width: 180px;
}