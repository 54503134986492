.profile-save-button {
  position: absolute;
  top: 39%;
  padding: 5px 20px;
}

.profile-save-button.mob {
  display: none;
}

.ellipsis--2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /*No of lines after which the ellipsis needs to be added*/
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.count.unread {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: -3px;
  margin-right: -5px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  background-color: #f7cc53;
  border-radius: 40px;
  color: #fff;
  border: 2px solid #fff;
}

.notification-card {
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
  padding: 10px !important;
  box-shadow: 0 0 10px #f1f1f1;
}

.my-bell-icon {
  font-size: 45px;
  opacity: 0.5;
}

.notification-card .notification-wrapper {
  max-height: 70vh;
  min-height: 400px;
}

.search-input i {
  z-index: 100;
}

.search-input-box .search {
  border: 1px solid #dbdfe2;
  border-radius: 6px;
  min-height: auto;
}

.search-input-box input {
  padding-right: 15px !important;
  font-size: 14px !important;
}

.search-input-box input:placeholder-shown {
  text-overflow: ellipsis;
}

.search-input-box i {
  z-index: 100;
}

select {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: no-wrap;
  padding-right: 40px !important;
}

.search {
  background-color: #fff;
  border-radius: 8px 0px 0px 8px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search .wrapper {
  box-shadow: none !important;
  font-size: 15px !important;
}

.search .wrapper:first-child {
  border-radius: 8px;
  border: none;
  z-index: 10;
}

.search .wrapper > div > svg {
  visibility: hidden;
}

.search .sc-gTRrQi {
  box-shadow: 0px 18px 18px 1px rgba(32, 33, 36, 0.1) !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.filter-border::before {
  z-index: 100;
}

.trusted-comp {
  max-height: 40px;
}

.home-carousel {
  margin-top: 110px;
}

.home-carousel .swiper-wrapper {
  max-height: 380px;
}

.home-carousel .swiper-slide {
  text-align: center;
}

.search-sec {
  max-width: 1000px;
  margin: auto;
}

.trending-keyword {
  color: #000 !important;
}

.search-sec .search-job-form {
  box-shadow: 0 0 15px rgb(0, 0, 0, 0.1);
  border-radius: 10px;
}

.client-swiper-wrapper {
  margin-top: 30px;
  max-height: 200px;
  overflow: hidden;
}

.client-swiper-wrapper .client-comp-img {
  max-height: 100px;
}

.my-link-btn {
  text-decoration: underline;
}

.my-link-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.link {
  cursor: pointer !important;
}

/* .autocomplete-select .select__control {
  border: none;
  width: 100%;
}

.autocomplete-select .select__indicator-separator {
  display: none;
}

.select__control {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

.autocomplete-select .select__placeholder {
  font-size: 13px;
}

.autocomplete-select .select__control input:focus {
  border: none;
  outline: none;
}

.autocomplete-select .select__value-container {
  padding-left: 40px !important;
} */

@media (max-width: 600px) {
  .notification-card .job-list-menu {
    flex-direction: row !important;
  }
  .profile-save-button {
    position: initial;
    display: none;
  }
  .profile-save-button.mob {
    display: block;
    margin: auto;
    margin-bottom: 30px;
  }

  .current_status {
    margin-top: -70px;
    font-size: 12px;
  }
}
